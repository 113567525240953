import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import StackLayout from "../../components/stackLayout"


class Analyse extends Component {

  allPosts = [
    ...(this.props.data.allWpTraining?.edges || []),
    ...(this.props.data.allWpTutorial?.edges || [])
  ];
  render() {
    return (
      <StackLayout postData={this.props.data.wpAnalyse} allPosts={this.allPosts} name="Analyses"/>
    )
  }
}

Analyse.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Analyse

export const pageQuery = graphql`
  query($id: String!) {
    wpAnalyse(id: { eq: $id }) {
      id
      title
      content
      excerpt
      acf {
        iconColor {
          sourceUrl
          mediaDetails {
            height
            width
          }
        }
        resources {
          text
          url
        }
        videoTutorial
        getMoreOut
        tenjinRelatedContent {
          ... on WpTraining {
            databaseId
            nodeType
            title
            slug
            uri
            acf {
              tenjinCardExcerpt
              tenjinArticleNumber
              tenjinUpcomingContent
              tenjinNoContent
              tenjinNewContent
              tenjinPaidContent
              tenjinRelatedContent {
                ... on WpAnalyse {
                  databaseId
                  id
                  slug
                  title
                  nodeType
                  uri
                  acf {
                    iconColor {
                      mediaDetails {
                        height
                        width
                      }
                      sourceUrl
                    }
                    tenjinUpcomingContent
                    tenjinNoContent
                    tenjinNewContent
                  }
                }
                ... on WpDestination {
                  databaseId
                  id
                  slug
                  title
                  nodeType
                  uri
                  acf {
                    iconColor {
                      mediaDetails {
                        height
                        width
                      }
                      sourceUrl
                    }
                    tenjinUpcomingContent
                    tenjinNoContent
                    tenjinNewContent
                  }
                }
                ... on WpSource {
                  databaseId
                  id
                  slug
                  title
                  nodeType
                  uri
                  acf {
                    iconColor {
                      mediaDetails {
                        height
                        width
                      }
                      sourceUrl
                    }
                    tenjinUpcomingContent
                    tenjinNoContent
                    tenjinNewContent
                  }
                }
              }
            }
          }
          ... on WpTutorial {
            databaseId
            nodeType
            title
            slug
            uri
            acf {
              tenjinCardExcerpt
              tenjinArticleNumber
              tenjinUpcomingContent
              tenjinNoContent
              tenjinNewContent
              tenjinPaidContent
              tenjinRelatedContent {
                ... on WpAnalyse {
                  databaseId
                  id
                  slug
                  title
                  nodeType
                  uri
                  acf {
                    iconColor {
                      mediaDetails {
                        height
                        width
                      }
                      sourceUrl
                    }
                    tenjinUpcomingContent
                    tenjinNoContent
                    tenjinNewContent
                  }
                }
                ... on WpDestination {
                  databaseId
                  id
                  slug
                  title
                  nodeType
                  uri
                  acf {
                    iconColor {
                      mediaDetails {
                        height
                        width
                      }
                      sourceUrl
                    }
                    tenjinUpcomingContent
                    tenjinNoContent
                    tenjinNewContent
                  }
                }
                ... on WpSource {
                  databaseId
                  id
                  slug
                  title
                  nodeType
                  uri
                  acf {
                    iconColor {
                      mediaDetails {
                        height
                        width
                      }
                      sourceUrl
                    }
                    tenjinUpcomingContent
                    tenjinNoContent
                    tenjinNewContent
                  }
                }
              }
            }
          }
          ... on WpAnalyse {
            id
            acf {
              iconColor {
                sourceUrl
                mediaDetails {
                  height
                  width
                }
              }
              tenjinUpcomingContent
              tenjinNoContent
              tenjinNewContent
              tenjinPopularity
            }
            title
            nodeType
            uri
            slug
            date
            categories: analyseCategories {
              nodes {
                slug
                name
              }
            }
          }
          ... on WpDestination {
            id
            acf {
              iconColor {
                sourceUrl
                mediaDetails {
                  height
                  width
                }
              }
              tenjinUpcomingContent
              tenjinNoContent
              tenjinNewContent
              tenjinPopularity
            }
            title
            nodeType
            uri
            slug
            date
            categories: destinationCategories {
              nodes {
                slug
                name
              }
            }
          }
          ... on WpSource {
            id
            acf {
              iconColor {
                sourceUrl
                mediaDetails {
                  height
                  width
                }
              }
              tenjinUpcomingContent
              tenjinNoContent
              tenjinNewContent
              tenjinPopularity
            }
            title
            nodeType
            uri
            slug
            date
            categories: sourceCategories {
              nodes {
                slug
                name
              }
            }
          }
        }
        tenjinUpcomingContent
        tenjinNoContent
        tenjinNewContent
        tenjinPageTitle
        tenjinHeadline
      },
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
            altText
            sourceUrl
            srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
            altText
            sourceUrl
            srcSet
        }
        canonical
        cornerstone
        schema {
            articleType
            pageType
            raw
        }
      }
    }
    allWpTraining(sort: { fields: [menuOrder], order: ASC }) {
      edges {
        node {
          acf {
            tenjinArticleNumber
            tenjinHeadline
            tenjinRelatedContent {
              ... on WpTraining {
                databaseId
                nodeType
                title
                slug
                uri
                acf {
                  tenjinCardExcerpt
                  tenjinArticleNumber
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                  tenjinPaidContent
                  tenjinRelatedContent {
                    ... on WpAnalyse {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                    ... on WpDestination {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                    ... on WpSource {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                  }
                }
              }
              ... on WpAnalyse {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
              ... on WpDestination {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
              ... on WpSource {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
            }
            tenjinCardExcerpt
            tenjinUpcomingContent
            tenjinNoContent
            tenjinNewContent
            tenjinPaidContent
          }
          title
          nodeType
          uri
          slug
          id
          author {
            node {
              name
              avatar {
                url
              }
              acf {
                tenjinWorkTitle
              }
            }
          }
        }
      }
    }
    allWpTutorial(sort: { fields: [menuOrder], order: ASC }) {
      edges {
        node {
          acf {
            tenjinArticleNumber
            tenjinHeadline
            tenjinRelatedContent {
              ... on WpTraining {
                databaseId
                nodeType
                title
                slug
                uri
                acf {
                  tenjinCardExcerpt
                  tenjinArticleNumber
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                  tenjinPaidContent
                  tenjinRelatedContent {
                    ... on WpAnalyse {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                    ... on WpDestination {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                    ... on WpSource {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                  }
                }
              }
              ... on WpTutorial {
                databaseId
                nodeType
                title
                slug
                uri
                acf {
                  tenjinCardExcerpt
                  tenjinArticleNumber
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                  tenjinPaidContent
                  tenjinRelatedContent {
                    ... on WpAnalyse {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                    ... on WpDestination {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                    ... on WpSource {
                      databaseId
                      id
                      slug
                      title
                      nodeType
                      uri
                      acf {
                        iconColor {
                          mediaDetails {
                            height
                            width
                          }
                          sourceUrl
                        }
                        tenjinUpcomingContent
                        tenjinNoContent
                        tenjinNewContent
                      }
                    }
                  }
                }
              }
              ... on WpAnalyse {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
              ... on WpDestination {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
              ... on WpSource {
                databaseId
                id
                slug
                title
                nodeType
                uri
                acf {
                  iconColor {
                    mediaDetails {
                      height
                      width
                    }
                    sourceUrl
                  }
                  tenjinUpcomingContent
                  tenjinNoContent
                  tenjinNewContent
                }
              }
            }
            tenjinCardExcerpt
            tenjinUpcomingContent
            tenjinNoContent
            tenjinNewContent
            tenjinPaidContent
          }
          title
          nodeType
          uri
          slug
          id
          author {
            node {
              name
              avatar {
                url
              }
              acf {
                tenjinWorkTitle
              }
            }
          }
        }
      }
    }
    allWpAnalyseCategory(filter: { parentId: { eq: null } }) {
      edges {
        node {
          name
          slug
          parentId
          id
          wpChildren {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`
